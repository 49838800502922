.ant-select-selector {
   border-radius: 8px !important; 
   border: 1px solid #ced4da !important;
   padding: 4px 8px !important; 
   font-size: 14px;
}

.ant-select-dropdown {
   border-radius: 8px !important;
   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.ant-select-item {
   padding: 8px 16px !important;
   font-size: 14px;
}

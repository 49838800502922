.scrollable-categories {
   display: flex;
   flex-wrap: nowrap;
   overflow-x: auto;
   white-space: nowrap;
   max-width: 300px;
}

.scrollable-categories::-webkit-scrollbar {
   height: 8px;
}

.scrollable-categories::-webkit-scrollbar-track {
   background: #f1f1f1;
}

.scrollable-categories::-webkit-scrollbar-thumb {
   background: #888;
}

.scrollable-categories::-webkit-scrollbar-thumb:hover {
   background: #555;
}
.button-wrapper {
    position: absolute;
    bottom: 80px;
    left: 4px; /* Adjust this value to align with your design */
    right: 4px; /* Adjust this value to align with your design */
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent; /* Use the same background color as your original container */
}

.center-locations {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 80px;
    left: 0;
    right: 0;
}

.buttons-wrapper {
    display: flex;
    /* Set a fixed width for the wrapper */
    max-width: 750px; /* You can adjust this width as needed */
    overflow-x: auto; /* Enable horizontal scrolling when content overflows */
    white-space: nowrap; /* Prevent buttons from wrapping to the next line */
}
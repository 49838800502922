@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.name {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0px;
}

.name h1 {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 140%;
	letter-spacing: -0.03em;
	color: #232323;
}

.name span {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	letter-spacing: -0.03em;
	color: #9a9a9a;
	flex-grow: 0;
}

.revenue-block {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24.5641px;
	gap: 24.56px;

	background: #ffffff;
	border-radius: 10.2351px;
}

.revenue-block .title {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 18.4231px;
	line-height: 150%;
	letter-spacing: -0.03em;
	color: #6c6c6c;
}

.value {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 32.7522px;
	line-height: 120%;
	letter-spacing: -0.03em;
	color: #240b3b;
}

.this-week {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 16.3761px;
	line-height: 150%;
	letter-spacing: -0.03em;
	color: #9a9a9a;
}

/* stats */

.statistics {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24.5641px;
	gap: 24.56px;

	background: #ffffff;
	border-radius: 10.2351px;
}

.statistics .revenue-stats {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 18.4231px;
	line-height: 150%;
	letter-spacing: -0.03em;
	color: #240b3b;
}

.statistics .weekly {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 16.3761px;
	line-height: 150%;
	letter-spacing: -0.03em;
	color: #6c6c6c;
}

.bookings {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 18.4231px;
	line-height: 150%;
	letter-spacing: -0.03em;
	color: #6c6c6c;
}

/* CustomCalendar.css */

.calendar-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.react-calendar {
	width: 100%;
	max-width: 100%;
	background: white;
	border: none;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 1.125em;
}

.react-calendar__tile {
	max-width: 55px !important;
	width: 100% !important;
	height: 100% !important;
	margin: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #240b3b;
	font-size: 14px;
	font-weight: 400;
	border-radius: 50%;
	aspect-ratio: 1 / 1 !important;
}

.react-calendar__tile--now {
	background: transparent !important;
}

.react-calendar__tile--active {
	background: #240b3b !important;
	color: white !important;
	border-radius: 50%;
	width: 100% !important;
	height: 100% !important;
	aspect-ratio: 1 / 1 !important;
}

.highlight {
	background: #240b3b !important;
	color: white !important;
	border-radius: 50%;
	width: 100% !important;
	height: 100% !important;
	aspect-ratio: 1 / 1 !important;
}

.today {
	background: transparent !important;
	color: #240b3b !important;
}

.react-calendar__navigation__label {
	font-size: 25px !important;
	font-family: 'Inter' !important;
	font-style: normal !important;
	font-weight: 600 !important;
	line-height: 30px !important;
	color: #240b3b !important;
}

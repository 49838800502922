/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Manrope:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;



:root {
  --color-primary: #543c7f;
  --color-black: #353535;
  --color-secondary: #32db64;
  --color-text: #222222;
  --color-gray: #454b4c;
  --color-red1: #f55a00;
  --color-blue1: #3865F3;
  --color-neutral: #19191C;
}

/*iframe {*/
/*  display: none;*/
/*}*/

* {
  box-sizing: border-box;
  /* font-family: 'Poppins', sans-serif !important; */
  font-family: 'Manrope', sans-serif !important;
}

html, body {
  background: white;
  height: 100%;
  padding: 0;
  margin: 0;
}

#root {
  width: 100% !important;
  height: 100% !important;
  overflow-y: hidden;
}

.hidden {
  display: none;
}

.flex_1 {
  flex: 1;
}

.bft-edit-form-header {
  margin-bottom: 50px !important;
}

.bft-edit-form-instruments {
  display: none !important;
}

.flex_wrap {
  flex-wrap: wrap;
}

.pos_relative {
  position: relative;
}

.row_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center-align {
  display: flex;
  justify-content: center;
}

.align-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-col-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.align-col-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.align-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.align-row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.align-end {
  display: flex;
  justify-content: flex-end;
}

.row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.bg-cover {
  background-repeat: no-repeat !important;
  background-position: 50% !important;
  background-size: cover !important;
}

.overlay-dark {
  transition: all 0.2s ease;
}

.overlay-dark:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  /*opacity: .2;*/
  /*background-color: black;*/
}

.overlay-dark>*,
.overlay-light>* {
  z-index: 1;
  position: relative;
}

.btn-style {
  cursor: pointer;
}

.hide-sm {
  display: inherit;
}

.sm-100 {
  width: inherit;
}

.sm-flex-100 {
  flex: 1;
}

.md-flex-100 {
  flex: 1;
}

.max-lg {
  max-width: 1600px;
}

.sub-container {
  padding: 24px;
  margin: 0 auto;
}

.loader {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: #22222230;
}

@media (max-width: 768px) {
  .md-flex-100 {
    flex: none;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .hide-sm {
    display: none;
  }

  .sm-100 {
    width: 100%;
  }

  .sm-flex-100 {
    flex: none;
    width: 100%;
  }
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.5) !important;
}

.MuiCircularProgress-svg {
  color: white;
}

.MuiTableContainer-root {
  box-shadow: none !important;
}

.MuiFormControlLabel-root .MuiRadio-root.Mui-checked {
  color: #240b3b !important;
}

.MuiTableRow-root, .MuiTableCell-root {
  height: 48px !important;
  padding: 4px !important;
}

thead .MuiTableRow-root,
thead .MuiTableCell-root {
  height: 44px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #d9d9d9 !important;
}

/* .MuiTextField-root .MuiOutlinedInput-root {
  height: 40px !important;
} */


.MuiButtonBase-root.MuiCheckbox-root {
  color: #667085 !important;
}
.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color: #240b3b !important;
}

input[type=checkbox] {
  background-color: gray;
}

.sidebar ::-webkit-scrollbar{
  opacity:0;
  /* background: transparent; */
  width: 1px;
}
.sidebar ::-webkit-scrollbar:hover{
  opacity: 1;
}

#tree .bft-input {
  display: none !important;
}

input[type="checkbox"]:checked {
  background-color: red;
}

.off-modal .react-datepicker-wrapper,
.off-modal .react-time-picker__wrapper,
.off-modal .react-time-picker {
  width: 100% !important;
  border-radius: 8px !important;
}

.custom-borders {
  border: 2px solid #F9F7F7 !important;
}

.order-history-container {
   position: relative;
  /* padding-left: 10px;
  background-image: linear-gradient(#000, #000),
                    linear-gradient(#000, #000),
                    linear-gradient(#000, #000),
                    linear-gradient(#000, #000);

  background-repeat: no-repeat;
  background-size: 8px 3px;
  background-position: top left, top right, bottom left, bottom right;
  border: solid #000;
  border-width: 0 2px; */
}


.order-history-container:before {
  content: " ";
  position: absolute;
  border-left:1px solid #000;
  border-top:1px solid #000;
  border-bottom:1px solid #000;
  padding:5px;
  top:-10px;
  bottom:-10px;
  left: -15px;
}

.fc-toolbar-chunk {
  display: none;
}

/* .swipe-modal {
  animation: swipeRightModal 0.3s forwards;
  background-color: rgba(0, 0, 0, 0.5)
}

@keyframes swipeRightModal {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
} */

.task-select .MuiSelect-outlined {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.housekeeping_task_due_date .MuiStack-root {
  padding-top: 0px !important;
}


/* TAGS FIELD */
.tagInputFieldClass {
  border: 1px solid #D9D9D9 !important;
  outline: none !important;
  border-radius: 4px !important;
  padding: 10px !important;
  height: 40px !important;
  width: 100% !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.tagClass {
  background-color: #240B3B !important;
  margin-right: 4px !important;
  margin-top: 4px !important;
  padding: 3px 8px !important;
  border-radius: 4px !important;
  color: #fff !important; 
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
.message-container {
	background: #e0e0e0;
	border-radius: 8px;
}

.search-bar input::placeholder {
	color: #92929d;
}

.message-search {
	height: 32px;
	background: #ffffff;
	border-radius: 10px;
	outline: none;
	padding-inline: 6%;
	border-radius: 8px;
}

.chat-list {
	max-width: 400px;
	border: 1px solid #ccc;
	border-radius: 10px;
}

.search-bar {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	border: none;
	border-bottom: 1px solid #ccc;
	outline: none;
	position: sticky;
	top: 0;
	left: 0;
	background-color: white;
}

.chat-item {
	display: flex;
	align-items: center;
	padding: 10px;
	border-bottom: 1px solid #ccc;
	cursor: pointer;
}

.chat-item:hover {
	background-color: #e6e6e6;
}

.avatar {
	width: 40px;
	height: 40px;
	background-color: #bbb;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 20px;
	margin-right: 10px;
}

.chat-details {
	flex: 1;
}

.chat-name-time .chat-name {
	font-family: 'Raleway', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 150%;
	display: flex;
	align-items: center;
	letter-spacing: -0.02em;
	font: bold;
	color: #000929;
}

.chat-time {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 11px;
	display: flex;
	align-items: center;
	text-align: right;
	letter-spacing: -0.01em;

	/* grey */
	color: rgba(118, 118, 124, 0.8);
}

.chat-message {
	font-size: 14px;
	color: #333;
	font-family: 'Raleway', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 11px;
	display: flex;
	align-items: center;
	letter-spacing: -0.02em;
	color: rgba(118, 118, 124, 0.8);
	flex: none;
	order: 0;
	flex-grow: 0;
}

/* Chat section */

.chat-section .header {
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #dfdfdf;
	border-radius: 10px;
}

.online {
	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	display: flex;
	align-items: center;
	letter-spacing: -0.02em;

	color: #bababa;
	flex: none;
	order: 1;
	flex-grow: 0;
}

.time-sent {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 13px;
	display: flex;
	align-items: center;
	color: #4f5665;
}

#ReactSimpleImageViewer {
	z-index: 99;
}
.month-calendar-cell {
    border: 1px solid #E8E8E8 !important;
  }

.month-calendar-header {
    border: 1px solid #E8E8E8 !important;

}

.month-calendar-row {
    min-height: 120px !important;
}
.messages-screen {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

*:not(input,textarea) {
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
 

.messages-screen .web-scroll-view {
  padding: 0 0px;
}

.messages-screen .web-scroll-view > div {
  width: 100%;
}

.messages-screen .order-chat-default-messages .react-horizontal-scrolling-menu--item div {
  white-space: nowrap;
}
 
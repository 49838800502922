@import url('https://fonts.googleapis.com/css2?family=Manrope:ital,wght@0,300;0,400;0,500;0,600;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');

.vision-track-sidebar {
	background: #ffffff;
	border-width: 0px 1px 0px 0px;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0.1);
	height: 100%;
}

.vision-track-sidebar .inactive {
	display: flex;
	align-items: center;
	padding: 5px 8px;
	gap: 12px;
	border-radius: 8px;
}

.vision-track-sidebar .active {
	display: flex;
	align-items: center;
	padding: 5px 8px;
	gap: 12px;
	border-radius: 8px;
}

.font-manrope {
	font-family: 'Manrope', sans-serif;
}
 
.stream-border {
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 9.33723px 11.2047px;
	gap: 7.47px;
	height: 30px;
	border: 0.933723px solid #d9d9d9;
	border-radius: 5.60234px;
	width: fit-content;
}

.stream-breadcrumb-name {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: #09244B;
}
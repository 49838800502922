.custom-timepicker input {
    height: 7px !important;
  }
  
  .custom-timepicker label {
    margin-top: -7px;
  }
  
  /* .avail-list .custom-timepicker .MuiStack-root.css-b3dvpx-MuiStack-root {
    padding-top: 12px;
  }
  .avail-list .custom-timepicker .MuiStack-root.css-b3dvpx-MuiStack-root label {
    color: #667085;
  }
  .avail-list .custom-timepicker .MuiFormControl-root.MuiTextField-root {
    min-width: 160px;
  }
  .avail-list .custom-timepicker .MuiInputBase-root.MuiOutlinedInput-root {
    width: 160px !important;
  }
  
  .small .MuiTextField-root {
    min-width: 160px !important;
    width: 160px;
    height: 44px !important;
  }
  .small .MuiTextField-root .MuiInputBase-root {
    height: 44px !important;
    border-radius: 8px !important;
    margin-top: -4px !important;
  } */
  
.chat-container::-webkit-scrollbar {
  width: 1px;
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.chat-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.chat-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.chat-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

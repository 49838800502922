@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');


.no-value-found {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 150%;
	text-align: center;
	letter-spacing: -0.03em;
	color: #240b3b;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.pagination-info {
	margin: 0 auto;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 50%;
	display: flex;
	align-items: center;
	letter-spacing: -0.03em;
	color: #6c6c6c;
}

.pagination-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 0 20px;
	gap: 16px;
	flex: 1;
}

.table-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px 23px 23px 0px;
}

.icon-wrapper {
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	gap: 10px;
	width: 26.14px;
	height: 26.14px;
	background: #ffffff;
	border: 0.933723px solid #e4e4e4;
	border-radius: 3.73489px;
}

.selected-page {
	display: flex !important;
	flex-direction: column !important;
	justify-content: center !important;
	align-items: center !important;
	padding: 9.33723px !important;
	gap: 9.34px !important;
	color: white !important;
	background: #240b3b !important;
	border-radius: 5.60234px !important;
	flex-grow: 0 !important;
}

.showRows {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 11.2px;
	color: #232323;
}

.cell-text,
.head-cell {
	font-family: 'Inter' !important;
	font-style: normal !important;
	line-height: 150% !important;
	letter-spacing: -0.03em !important;
	padding-inline: 20px !important;
}

.cell-text {
	color: #232323 !important;
	font-weight: 500 !important;
}

.head-cell {
	font-weight: 600 !important;
	color: #6c6c6c !important;
}

.confirm-gold {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px 10px;
	gap: 9.34px;
	background: rgb(2 122 72);
	border-radius: 5.60234px;
}

.cancelled-gold {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px 10px;
	gap: 9.34px;
	background: rgb(251 78 78);
	border-radius: 5.60234px;
}

.view-breaks {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 12.2821px;
	line-height: 150%;
	display: flex;
	align-items: center;
	letter-spacing: -0.03em;
	color: #232323;
}
.footer-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px;
	border-radius: 8px;
	padding: 10px;
}

.footer-wrapper span {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: rgba(26, 26, 26, 0.7);
}
.emoji-picker {
  display: flex;
  position: absolute;
  bottom: 75px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 120px;
  background-color: #00f;
  justify-content: space-around;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 10px;
}

.emoji-picker button {
  width: 28px;
  height: 28px;
  border: none;
  padding: 2px;
  border-radius: 5px;
  background-color: white;
  text-transform: none;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

img.emoji {
  height: 1.3em;
  width: 1.3em;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.1em;
}

.avatar-image .css-1pqm26d-MuiAvatar-img {
  width: 90% !important;
  height: 85% !important;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
}

.bronze {
	display: flex;
	padding: 1.86745px 11.2047px;
	gap: 9.34px;
	border-radius: 5.60234px;
	width: fit-content;
	color: #2B2B2B;
}

.add-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 14.9396px;
	gap: 7.47px;
	height: 39px;
	background: #240b3b;
	border-radius: 5.60234px;
}
.MuiPickersToolbar-root{
    display: none !important;
}
.MuiPickersLayout-contentWrapper {
    grid-column: 1 !important;
}
.MuiDialogActions-root {
    display: none !important;
}
.width-full{
    width: 100% !important;
}
/* .MuiDayCalendar-weekContainer .MuiButtonBase-root{
    border-radius: 0 !important;
    border: 1px solid #DDD !important;
    margin: 0 !important;
}
.MuiDayCalendar-weekContainer{
    margin: 0 !important;
}
.MuiDayCalendar-header{
    margin: 0 !important;
}
.MuiPickersDay-dayWithMargin{
    margin: 0 !important;
}
.MuiDayCalendar-weekContainer .MuiPickersDay-root{
    border-radius: 0 !important;
    border: 1px solid #DDD !important;
    margin: 0 !important;
}
.MuiTypography-root {
     
    
    margin: 0 !important;
} */

.month-calendar-cell {
    /* border: 1px solid #E8E8E8 !important; */
  }

.month-calendar-header {
    /* border: 1px solid #E8E8E8 !important; */

}

.month-calendar-row {
    min-height: 120px !important;
}

.within-range {
    background-color: #e0e0e0;  /* A light gray for demonstration */
}
.range-bar {
    background-color: #5C1C81;
        /* Color of the event bar */
    color: white;            /* Color of the text on the bar */
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;             /* Ensure it's above other elements */
    height: 24px;            /* Thickness of the bar */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;         /* Show a hand cursor when hovering over the event bar */
    margin-left: -27px; 
    margin-right: -27px;
    border-radius: 8px;
}

.month-calendar-cell {
    border-right: 1px solid transparent;
    border-left: 1px solid transparent; 
}

.loader-refresh {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.no-border {
  border: none;
}

.tooltip-content {
  padding: 0.625rem;
  font-size: 0.75rem;
}

.bold-text {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.threat-level {
   box-sizing: border-box;
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 7px 10px 7px 16px;
   gap: 14.94px;
   background: #FFFFFF;
   border: 0.933723px solid #D9D9D9;
   border-radius: 5.60234px;
}

.threat-level span {
   font-family: 'Inter';
   font-style: normal;
   font-weight: 500;
   font-size: 12px;
   line-height: 150%;
   letter-spacing: -0.03em;
   color: #240B3B;
}

.threat-card {
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   padding: 2px 12px;
   gap: 10px;
   background: #FDF6DD;
   border-radius: 6px;
}

.threat-card span {
   font-family: 'Inter';
   font-style: normal;
   font-weight: 500;
   font-size: 12px;
   line-height: 150%;
   display: flex;
   align-items: center;
   text-align: center;
   letter-spacing: -0.03em;
   color: #F4BC5E;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

.filter-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-inline: 23px;
	margin-top: 10px;
	gap: 22.41px;
}

@media (max-width: 768px) {
	.filter-container {
		flex-direction: column;
		justify-content: center;
		padding-inline: 10px;
		gap: 10px;
	}
}
.filter-container .input-search {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding: 11.2047px 14.9396px;
	gap: 11.2px;
	background: #ffffff;
	border: 0.933723px solid #d9d9d9;
	border-radius: 5.60234px;
	outline: none;
}

.input-search input {
	outline: none;
	padding-inline: 20px;
	width: 100%;
}

.select {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 11.2047px 22.4094px;
	background: #ffffff;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	width: 100%;
}

.search-icon {
	position: absolute;
	width: 16.81px;
	height: 16.81px;
	left: 10px;
	top: 25%;
}

.cell-text {
	font-family: 'Inter' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	letter-spacing: -0.03em !important;
	color: #232323 !important;
}

.add-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 14.9396px;
	gap: 7.47px;
	height: 39px;
	background: #240b3b;
	border-radius: 5.60234px;
}

.title {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	text-align: center;
	letter-spacing: -0.03em;
	color: #240b3b;
}

.last-touch-title {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	display: flex;
	align-items: center;
	letter-spacing: -0.03em;
	color: #232323;
	flex-grow: 1;
}

.dropdown-item {
	padding: 5px 10px;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: start;
}

.code-style {
    background-color: #f6f7fb;
    border-color: #dcdfec;
    border-style: solid;
    border-width: 1px;
    color: #bc14fe;
    font-size: 75%;
    margin-inline: 2px;
    padding: 2px 4px;
    white-space: break-spaces;
}
.googlemap{
    width: 100% !important;
    height: 400px !important;
    position: relative !important;
}
.gmnoprint{
    display: none !important;
}
.gm-control-active{
    display: none !important;
}
.gm-style a img{
    display: none !important;
}
.title {
   font-family: 'Inter';
   font-style: normal;
   font-weight: 700;
   font-size: 19px;
   line-height: 24px;
   color: #250B3B;
}

.description {
   font-family: 'Inter';
   font-style: normal;
   font-weight: 400;
   font-size: 17px;
   line-height: 29px;
   color: #0F0F0F;
}

.card-wrapper {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
   padding: 12px;
   gap: 12px;
   background: #FFFFFF;
   border-radius: 12px;
}

.card-wrapper .card-title {
   font-family: 'Inter';
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 22px;
   color: rgba(26, 26, 26, 0.7);
}

.card-wrapper .username {
   font-family: 'Inter';
   font-style: normal;
   font-weight: 700;
   font-size: 20px;
   line-height: 32px;
   color: #1A1A1A;
}


.user-information {
   display: flex;
   justify-content: start;
   align-items: center;
   padding: 4px 0px;
   gap: 10px;
}

.user-information .score {
   font-family: 'Inter';
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 24px;
   color: rgba(26, 26, 26, 0.7);
}

.card-btn {
   display: flex;
   align-items: center;
   padding: 0px;
   gap: 40px;
}

.card-btn button {
   box-sizing: border-box;
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 7.46978px;
   gap: 9.34px;
   background: #FFFFFF;
   border: 0.933723px solid #E4E4E4;
   border-radius: 3.73489px;
}

.card-btn .btn-details {
   font-family: 'Inter';
   font-style: normal;
   font-weight: 500;
   font-size: 12.2821px;
   line-height: 150%;
   display: flex;
   align-items: center;
   letter-spacing: -0.03em;
   color: #232323;
}

.comparison-section {
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.comparison-title {
   font-family: 'Inter';
   font-style: normal;
   font-weight: 700;
   font-size: 20px;
   line-height: 32px;
   color: #1A1A1A;
   display: flex;
}

.comparison-data {
   display: flex;
   flex-direction: column;
   gap: 10px;
   align-items: start;
   justify-content: start;
}

.data-item {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 10px 0px;
   gap: 10px;
   width: 100%;
   height: 44px;
   background: #3D205A;
   border-radius: 10px;
}

.data-value {
   font-size: 18px;
   font-weight: bold;
   color: white;
}

.current-data-label {
   font-family: 'Inter';
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 24px;
   text-align: center;
   color: rgba(26, 26, 26, 0.7);
}

.submenu-link {
  font-size: 16px !important;
  line-height: 150% !important;
  color: var(--color-text) !important;
  font-weight: 500 !important;
}

.product-list-container {
  height: 500px; /* Set the desired height */
  overflow-y: auto; /* Enable vertical scrolling if necessary */
  position: relative; /* Ensure relative positioning for child elements */
}


.Dropdown-control {
  
  padding-left: 17px !important;
  min-height: 40px !important;
}

.Dropdown-arrow {
  margin-top: 3px;
}
.schedule-modal .react-datepicker-wrapper {
  border-radius: 22px;
  border: 1px solid gray;
  padding: 8px;
  padding-left: 16px;
  width: 200px;
}
.schedule-modal .react-datepicker__input-container {
  display: flex;
  justify-content: center;
}
.schedule-modal .react-time-picker__wrapper {
  padding: 8px;
  border-radius: 22px;
}
.header-menu {
  top: 70px !important;
}

.header-menu .ant-dropdown-menu {
  padding: 20px 10px;
  border-radius: 16px;
}

.header-menu .ant-dropdown-menu-item {
  margin: 10px 0px !important;
}
/* SIDEBAR */
.menu_active {
  background: linear-gradient(218.57deg, #23cbd8 -6.67%, #3865f3 137.69%);
}

.main-body {
  height: calc(100vh - 76px);
  overflow: auto;
}

/* SIDEBAR */
/*
body {
  margin: 0;
  font-family: "Figtree", -apple-system, BlinkMacSystemFont, "Segoe UI", "Figtree", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #ffffff;
  font-size: 24px;
}
.app {
  background: #a8d5e5;
}
h1 {
  color: #165a72;
  margin: 50px auto;
  font-size: 40px;
}
.menu1 {
  margin-bottom: 40px;
  margin-top: 20px;
}
h2 {
  color: #165a72;
}*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

#camera_position .ant-select-selector {
	padding: 8px !important;
}

.devices-filter-container .input-search {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding: 11.2047px 14.9396px;
	gap: 11.2px;
	background: #ffffff;
	border: 0.933723px solid #d9d9d9;
	border-radius: 5.60234px;
	outline: none;
}

.input-search input {
	outline: none;
	padding-inline: 20px;
	padding-inline-end: 0 !important;
	width: 100%;
}

.select {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 11.2047px 22.4094px;
	background: #ffffff;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	width: 100%;
}

.search-icon {
	position: absolute;
	width: 16.81px;
	height: 16.81px;
	left: 10px;
	top: 25%;
}

.cell-text {
	font-family: 'Inter' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	letter-spacing: -0.03em !important;
	color: #232323 !important;
}

.add-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 14.9396px;
	gap: 7.47px;
	height: 39px;
	background: #240b3b;
	border-radius: 5.60234px;
}

.title {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	text-align: center;
	letter-spacing: -0.03em;
	color: #240b3b;
}

.last-touch-title {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	display: flex;
	align-items: center;
	letter-spacing: -0.03em;
	color: #232323;
	flex-grow: 1;
}

.dropdown-item {
	padding: 5px 10px;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: start;
}

.setup-status {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px 12px;
	gap: 10px;
	width: 85px;
	background: #e7f7ed;
	border-radius: 6px;
}

.setup-text {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 150%;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.03em;
}
